import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from "@angular/core";
import { TypeManagerDecorator } from "../../main/type.map.service";
import { ColorBucketType, ItemColorMappingService } from "../../services/item.color.mapping.service";
import { NgStyle } from "@angular/common";

@TypeManagerDecorator("s25-ng-avail-legend")
@Component({
    selector: "s25-ng-avail-legend",
    template: `
        @if (isInit) {
            <ul class="avail-legend">
                <li class="grid-color" data-type="event" [tabindex]="0">Default Event Green</li>
                <li class="grid-color" data-type="related" [tabindex]="0">Related Event Orange</li>
                <li class="grid-color" data-type="pending" [tabindex]="0">Pending Event Purple</li>
                <li class="grid-color" data-type="requested" [tabindex]="0">Requested Event (Color May Vary)</li>
                <li class="grid-color" data-type="draft" [tabindex]="0">Draft Event (Color May Vary)</li>
                <li class="grid-color" data-type="blackout" [tabindex]="0">Blackout Grey</li>
                <li class="grid-color" data-type="closed" [tabindex]="0">Closed Hours Grey</li>
                @for (bucket of bucketType.buckets; track bucket) {
                    <li
                        [tabindex]="0"
                        [ngStyle]="{
                            backgroundColor: bucket.color.hash,
                            color: bucket.color.textColor,
                            backgroundImage: bucket.color.pattern?.css,
                        }"
                    >
                        {{ bucket.bucket_name }} {{ bucket.color.name }}
                    </li>
                }
            </ul>
        }
    `,
    styles: `
        ul {
            list-style-type: none;
            padding: 0;
        }

        li {
            margin: 0.5em 0;
            padding: 0.5em;
            border-radius: 3px;
        }
    `,
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgStyle],
})
export class S25AvailLegendComponent implements OnInit {
    bucketType: ColorBucketType;
    isInit = false;

    constructor(private changeDetector: ChangeDetectorRef) {}

    async ngOnInit() {
        this.bucketType = await ItemColorMappingService.getEnabledBucketType();
        // Need to remove the ";" off any CSS patterns
        for (let bucket of this.bucketType?.buckets?.filter((bucket) => bucket.color.pattern?.css) || []) {
            bucket.color.pattern.css = bucket.color.pattern.css.replace(/;$/, "");
        }
        this.isInit = true;
        this.changeDetector.detectChanges();
    }
}
