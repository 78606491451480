import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from "@angular/core";
import { TypeManagerDecorator } from "../../main/type.map.service";
import { S25MultiAvailWeeklyComponent } from "./s25.multi.avail.weekly.component";
import { S25ItemI } from "../../pojo/S25ItemI";

@TypeManagerDecorator("s25-ng-embedded-multi-avail-weekly")
@Component({
    selector: "s25-ng-embedded-multi-avail-weekly",
    standalone: true,
    template: `
        <s25-ng-multi-avail-weekly
            [hasTabs]="false"
            [hasHelp]="false"
            [query]="query"
            [searchChooser]="searchChooser"
            [searchOptions]="searchOptions"
            [searchChoice]="searchChoice"
        />
    `,
    styles: ``,
    encapsulation: ViewEncapsulation.Emulated,
    imports: [S25MultiAvailWeeklyComponent],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25EmbeddedMultiAvailWeeklyComponent implements OnInit {
    query: string;
    searchChooser: S25MultiAvailWeeklyComponent["searchChooser"] = "none";
    searchOptions: S25ItemI[];
    searchChoice: S25ItemI[];

    ngOnInit() {
        const params = new URLSearchParams(window.location.search);
        const queryId = params.get("queryId");
        const searchQuery = params.get("searchQuery");
        this.query = queryId ? `&space_query_id=${queryId}` : searchQuery;
        const config = window.ProData.embeddedConfig;
        if (config.hasMultiQuery) {
            this.searchChooser = config.multiQueryDropdown ? "single" : "multi";
            this.searchOptions = config.queryList;
            const choice: S25ItemI = { itemId: config.queryId, itemName: config.queryName };
            this.searchChoice = [choice];
            const hasChoice = config.queryList.some((query: S25ItemI) => query.itemId === config.queryId);
            if (!hasChoice) this.searchOptions.unshift(choice);
        }
    }
}
