import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewEncapsulation,
} from "@angular/core";
import { TypeManagerDecorator } from "../../main/type.map.service";
import { AvailWeekly } from "./s25.avail.weekly.util";
import { S25ButtonComponent } from "../../standalone/s25.button.component";
import { FormsModule } from "@angular/forms";
import { S25HelpModule } from "../s25-help/s25.help.module";
import { S25IconModule } from "../s25-icon/s25.icon.module";
import { S25AvailLegendComponent } from "./s25.avail.legend.component";
import { S25ModalModule } from "../s25-modal/s25.modal.module";
import { AvailService } from "../../services/avail.service";
import { TelemetryService } from "../../services/telemetry.service";
import { S25Util } from "../../util/s25-util";
import { S25DayChooserModule } from "../s25-day-chooser/s25.day.chooser.module";
import { S25InlineDatepickerComponent } from "../../standalone/s25.inline.datepicker.component";
import { S25CheckboxModule } from "../s25-checkbox/s25.checkbox.module";
import { DayChooserPref } from "../s25-day-chooser/s25.day.chooser.component";
import { S25ItemI } from "../../pojo/S25ItemI";
import { S25DropdownPaginatedModule } from "../s25-dropdown/s25.dropdown.paginated.module";
import { SearchService } from "../../services/search/search.service";
import { Item } from "../../pojo/Item";

@TypeManagerDecorator("s25-ng-avail-weekly-options")
@Component({
    selector: "s25-ng-avail-weekly-options",
    template: `
        @if (searchChooser === "single" && searchOptions?.length) {
            <s25-generic-dropdown
                class="search-selector"
                [items]="searchOptions"
                [groupProp]="''"
                [chosen]="searchChoice[0]"
                (chosenChange)="searchChoice[0] = $event; searchChoiceChange.emit(searchChoice)"
            />
        }
        @if (searchChooser === "multi" && searchOptions?.length) {
            <s25-ng-generic-multiselect-dropdown
                class="search-selector"
                [items]="searchOptions"
                [groupProp]="''"
                [(chosen)]="searchChoice"
                (done)="searchChoiceChange.emit(searchChoice)"
                [placeholder]="'Select Searches'"
            />
        }
        <s25-ng-checkbox [(modelValue)]="includeRequested" (modelValueChange)="onIncludeRequestedChange($event)">
            Include Requested
        </s25-ng-checkbox>
        <s25-ng-inline-datepicker
            [(firstDate)]="firstDate"
            [weeks]="weeks"
            (firstDateChange)="firstDateChange.emit($event); changed.emit()"
        />
        <label class="weeks">
            <span>Weeks:</span>
            <select
                class="cn-form__control"
                [(ngModel)]="weeks"
                (ngModelChange)="weeksChange.emit($event); changed.emit()"
            >
                @for (week of AvailWeekly.WEEK_OPTIONS; track week) {
                    <option [ngValue]="week">{{ week }}</option>
                }
            </select>
        </label>
        <s25-ng-day-chooser (onChange)="daysChange.emit($event)" />
        <select
            class="cn-form__control"
            [attr.aria-label]="'Utilization'"
            [(ngModel)]="utilizationMode"
            (ngModelChange)="utilizationModeChange.emit($event)"
        >
            <option [ngValue]="'none'">No Utilization</option>
            <option [ngValue]="'RHC/EHC'">RHC/EHC</option>
            <option [ngValue]="'RHC/CAP'">RHC/CAP</option>
            <option [ngValue]="'EHC/CAP'">EHC/CAP</option>
        </select>
        <select class="cn-form__control" [attr.aria-label]="'Mode'" [(ngModel)]="mode" (ngModelChange)="onModeChange()">
            <option [ngValue]="'overlapping'">Overlapping</option>
            <option [ngValue]="'separated'">Separated</option>
        </select>
        <s25-ng-button [type]="'outline'" (click)="legend.emit()" class="legend">Legend</s25-ng-button>
        <s25-ng-button [type]="'none'" (click)="refresh.emit()" class="refresh" [ariaLabel]="'Refresh'"
            ><s25-ng-icon [type]="'refresh'"></s25-ng-icon
        ></s25-ng-button>
        @if (hasHelp) {
            <s25-help-link [helpTopic]="'view_locsingle_weekly'" [styleAsLink]="true">
                <s25-ng-icon [type]="'help'" />
                Help
            </s25-help-link>
        }
    `,
    styles: `
        :host {
            display: flex;
            padding-block: 0.25rem;
            justify-content: space-between;
            flex-wrap: wrap;
            gap: 0.5rem;
            align-items: center;
        }

        .search-selector {
            min-width: 20em;
        }

        .weeks {
            display: flex;
            align-items: center;
            gap: 0.25em;
        }

        .refresh s25-ng-icon {
            color: #2573a7;
            font-size: 1rem !important;
        }

        s25-help-link {
            align-self: center;
        }
    `,
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.Emulated,
    imports: [
        S25HelpModule,
        S25IconModule,
        S25ButtonComponent,
        FormsModule,
        S25AvailLegendComponent,
        S25ModalModule,
        S25DayChooserModule,
        S25InlineDatepickerComponent,
        S25CheckboxModule,
        S25DropdownPaginatedModule,
    ],
})
export class S25AvailWeeklyOptionsComponent implements OnInit {
    @Input() includeRequested: boolean = false;
    @Input() firstDate: Date = new Date();
    @Input() weeks: number = 1;
    @Input() utilizationMode: AvailWeekly.UtilizationMode = "none";
    @Input() mode: AvailWeekly.Mode = "overlapping";
    @Input() hasHelp: boolean = true;
    @Input() searchChooser: "none" | "single" | "multi" = "none";
    @Input() searchOptions: S25ItemI[];
    @Input() searchChoice: S25ItemI[];

    @Output() includeRequestedChange = new EventEmitter<boolean>();
    @Output() firstDateChange = new EventEmitter<Date>();
    @Output() weeksChange = new EventEmitter<number>();
    @Output() daysChange = new EventEmitter<DayChooserPref>();
    @Output() utilizationModeChange = new EventEmitter<AvailWeekly.UtilizationMode>();
    @Output() modeChange = new EventEmitter<AvailWeekly.Mode>();
    @Output() legend = new EventEmitter<void>();
    @Output() changed = new EventEmitter<void>();
    @Output() refresh = new EventEmitter<void>();
    @Output() searchChoiceChange = new EventEmitter<S25ItemI[]>();

    protected readonly AvailWeekly = AvailWeekly;

    constructor(private changeDetector: ChangeDetectorRef) {}

    async ngOnInit() {
        const [separated] = await Promise.all([AvailService.getSeparatedPref(), this.getSearches()]);
        const mode = separated ? "separated" : "overlapping";
        if (mode !== this.mode) {
            this.mode = mode;
            this.modeChange.emit(this.mode);
            this.changeDetector.detectChanges();
        }
    }

    async getSearches() {
        if (this.searchChooser === "none") return;
        if (this.searchOptions?.length) return; // Already have searches

        const searches = await SearchService.getStrictSearches(Item.Ids.Location);
        this.searchOptions = S25Util.array.uniqueByProp([this.searchChoice[0], ...searches.opt], "itemId");
    }

    onIncludeRequestedChange(include: boolean) {
        if (this.includeRequested) this.telemetry("IncludeRequested");
        this.includeRequestedChange.emit(include);
        this.changed.emit();
    }

    onModeChange() {
        this.telemetry(this.mode);
        AvailService.setSeparatedPref(this.mode === "separated");
        this.modeChange.emit(this.mode);
        this.changed.emit();
    }

    telemetry(type: string) {
        TelemetryService.sendWithSub("Avail", "Weekly", S25Util.firstCharToUpper(type));
    }
}
