import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    QueryList,
    Renderer2,
    SimpleChanges,
    ViewChildren,
    ViewEncapsulation,
} from "@angular/core";
import { TypeManagerDecorator } from "../../main/type.map.service";
import { AvailWeekly } from "./s25.avail.weekly.util";
import { Bind } from "../../decorators/bind.decorator";
import { SpaceService } from "../../services/space.service";
import { S25AvailWeeklyComponent } from "./s25.avail.weekly.component";
import { NgTemplateOutlet, SlicePipe } from "@angular/common";
import { S25ButtonComponent } from "../../standalone/s25.button.component";
import { FormsModule } from "@angular/forms";
import { S25AvailLegendComponent } from "./s25.avail.legend.component";
import { S25ModalModule } from "../s25-modal/s25.modal.module";
import { S25AvailWeeklyOptionsComponent } from "./s25.avail.weekly.options.component";
import { DayChooserPref } from "../s25-day-chooser/s25.day.chooser.component";
import { S25ItemModule } from "../s25-item/s25.item.module";
import { S25IconModule } from "../s25-icon/s25.icon.module";
import { S25OptModule } from "../s25-opt/s25.opt.module";
import { S25ItemI } from "../../pojo/S25ItemI";
import { SearchUtil } from "../../services/search/s25.search.util";
import { SearchCriteria } from "../../pojo/SearchCriteria";
import { S25LoadingInlineModule } from "../s25-loading/s25.loading.inline.module";

@TypeManagerDecorator("s25-ng-multi-avail-weekly")
@Component({
    selector: "s25-ng-multi-avail-weekly",
    template: `
        @if (hasTabs) {
            <s25-ng-opt-visualization-buttons
                [hasList]="true"
                [hasCalendar]="true"
                [hasAvailability]="true"
                [hasAvailabilityWeekly]="true"
                [selected]="tab"
                (vizChange)="onTabChange($event)"
            />
        }
        <s25-ng-avail-weekly-options
            [(includeRequested)]="includeRequested"
            [(firstDate)]="firstDate"
            [(weeks)]="weeks"
            (daysChange)="dayChooserChange($event)"
            [(utilizationMode)]="utilizationMode"
            (utilizationModeChange)="onUtilizationChange()"
            [(mode)]="mode"
            (legend)="legend.open()"
            (changed)="detectChanges()"
            (refresh)="refreshGrids(); refreshSearch()"
            [searchChooser]="searchChooser"
            [searchOptions]="searchOptions"
            [(searchChoice)]="searchChoice"
            (searchChoiceChange)="refreshSearch()"
            [hasHelp]="hasHelp"
        />

        @if (loading) {
            <s25-ng-loading-inline-static class="loading" />
        }

        <s25-ng-modal #legend [title]="'Availability Color Legend'" [size]="'xs'">
            <ng-template #s25ModalBody>
                <s25-ng-avail-legend />
            </ng-template>
        </s25-ng-modal>

        @if (!loading && spaces?.length === 0) {
            <div class="no-results">No Results</div>
        }

        @for (space of spaces | slice: 0 : visibleSpaces; track space.id) {
            <div #collapse class="collapser location" collapsed="false">
                <div class="header" (click)="toggleCollapse(collapse)">
                    <s25-ng-icon [type]="'location'" class="location-icon" />
                    <s25-item-space
                        [modelBean]="{ itemId: space.id, itemTypeId: 4, itemName: space.name }"
                        (click)="$event.stopPropagation()"
                    />
                    <div class="align-right">
                        <s25-ng-button
                            (click)="toggleCollapse(collapse)"
                            [ariaLabel]="'Collapse or Expand'"
                            [type]="'none'"
                            class="toggle-collapse"
                        >
                            <s25-ng-icon [type]="'caretUp'" />
                        </s25-ng-button>
                    </div>
                </div>
                <div class="body">
                    <s25-ng-avail-weekly
                        [itemType]="4"
                        [itemId]="space.id"
                        [noOptions]="true"
                        [includeRequested]="includeRequested"
                        [firstDate]="firstDate"
                        [weeks]="weeks"
                        [dows]="dows"
                        [utilizationMode]="utilizationMode"
                        [mode]="mode"
                        [startHour]="startHour"
                        [endHour]="endHour"
                        (hoursChange)="hoursChange($event)"
                    />
                </div>
            </div>
        }
        @if (spaces.length > visibleSpaces) {
            <div class="footer-buttons">
                <s25-ng-button class="show-more" [type]="'primary'" (click)="showMore()">Show More</s25-ng-button>
            </div>
        }
    `,
    styles: `
        s25-ng-opt-visualization-buttons {
            padding-top: 1rem;
            display: block;
        }

        s25-ng-avail-weekly-options {
            padding-bottom: 1rem;
        }

        .loading {
            display: flex;
            justify-content: center;
            padding: 0.5rem;
        }

        .no-results {
            color: #6c6c6c;
            font-size: 1.333rem;
            text-align: center;
            padding: 1rem 0;
        }

        .collapser {
            padding-bottom: 0.5rem;

            .header {
                display: flex;
                justify-content: space-between;
                background: white;
                border-top-left-radius: 3px;
                border-top-right-radius: 3px;
                border: 1px solid rgba(0, 0, 0, 0.15);
                padding-inline: 0.3125rem;
                padding-block: 0.625rem;
                align-items: center;
                gap: 0.25em;
                cursor: pointer;
                position: relative;

                &::before {
                    background-color: #0077d7;
                    border-top-left-radius: 3px;
                    bottom: 0;
                    content: "";
                    left: 0;
                    position: absolute;
                    top: 0;
                    width: 3px;
                }

                .location-icon {
                    font-size: 1.3em;
                }

                s25-item-space {
                    font-weight: bold;
                }

                .align-right {
                    font-size: 1.25em;
                    text-align: end;
                    flex-grow: 1;
                }
            }

            .body {
                padding-top: 0.5em;
                padding-bottom: 2rem;
            }

            &[collapsed="true"] {
                .toggle-collapse s25-ng-icon {
                    rotate: 180deg;
                }

                .body {
                    display: none;
                }
            }
        }

        s25-item-space {
            cursor: pointer;
        }

        .footer-buttons {
            display: flex;
            justify-content: center;
            padding-block: 1em;
        }

        ::ng-deep .nm-party--on s25-ng-multi-avail-weekly .collapser .header {
            background-color: #28272c !important;
            border-color: rgba(255, 255, 255, 0.24) !important;
        }
    `,
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.Emulated,
    imports: [
        S25AvailWeeklyComponent,
        SlicePipe,
        S25ButtonComponent,
        FormsModule,
        S25AvailLegendComponent,
        S25ModalModule,
        S25AvailWeeklyOptionsComponent,
        NgTemplateOutlet,
        S25ItemModule,
        S25IconModule,
        S25OptModule,
        S25LoadingInlineModule,
    ],
})
export class S25MultiAvailWeeklyComponent implements OnChanges, OnInit {
    @Input() query: string;
    @Input() queryGenerator: () => Promise<{ query: string; postSearch?: () => void }>; // Primarily for temp searches. If set, this will be called to get the query
    @Input() tab: AvailWeekly.Tab;
    @Input() tabChangeCallback: (tab: AvailWeekly.Tab) => void; // ONLY FOR INTERFACE WITH JS COMPONENTS. USE @Output FOR TS COMPONENTS
    @Input() hasTabs: boolean = true;
    @Input() searchChooser: "none" | "single" | "multi" = "none";
    @Input() searchOptions: S25ItemI[];
    @Input() searchChoice: S25ItemI[];
    @Input() hasHelp: boolean = true;

    @Output() tabChange = new EventEmitter<AvailWeekly.Tab>();

    @ViewChildren(S25AvailWeeklyComponent) weeklyGrids: QueryList<S25AvailWeeklyComponent>;

    loading: boolean = true;
    spaces: { name: string; id: number }[] = [];
    visibleSpaces: number = AvailWeekly.MIN_SPACES;
    includeRequested: boolean = false;
    firstDate: Date = new Date();
    weeks: number = 1;
    utilizationMode: AvailWeekly.UtilizationMode = "none";
    mode: AvailWeekly.Mode = "overlapping";
    dows: Set<AvailWeekly.Dow> = new Set();
    startHour: number = 0;
    endHour: number = 23;

    constructor(
        private changeDetector: ChangeDetectorRef,
        private renderer: Renderer2,
        private elementRef: ElementRef,
    ) {
        elementRef.nativeElement.angBridge = this;
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.query && !changes.query.firstChange) this.refreshSearch();
        if (changes.queryGenerator && !changes.queryGenerator.firstChange) this.refreshSearch();
    }

    ngOnInit() {
        this.refreshSearch();
    }

    setLoading(loading: boolean) {
        this.loading = loading;
        this.changeDetector.detectChanges();
    }

    async refreshSearch() {
        this.setLoading(true);
        const { query, postSearch } = await this.getQuery();
        if (query) {
            const data = await SpaceService.getSpacesBySearchQuery(query.replace(/spaces?_/gi, ""), null);
            this.spaces = (data.spaces?.space || []).map((space) => ({
                name: String(space.space_name),
                id: space.space_id,
            }));
            this.visibleSpaces = AvailWeekly.MIN_SPACES;
        }
        postSearch?.();
        this.setLoading(false);
    }

    async getQuery(): Promise<{ query: string; postSearch?: () => void }> {
        if (this.searchChooser === "multi" && this.searchChoice.length === 0) {
            alert("Please select at least one search");
            return { query: null };
        } else if (
            this.searchChooser === "single" ||
            (this.searchChooser === "multi" && this.searchChoice.length === 1)
        ) {
            return { query: `&space_query_id=${this.searchChoice[0].itemId}` };
        } else if (this.searchChooser === "multi") {
            return await this.getMultiSearch();
        } else if (this.queryGenerator) {
            return await this.queryGenerator();
        }
        return { query: this.query };
    }

    getMultiSearch() {
        // Create temp search for queries
        const model: SearchCriteria.Model = {
            query_method: "any",
            step: this.searchChoice.map((item) => ({
                step_type_id: SearchCriteria.StepType.Location.Search,
                step_param: [{ itemId: item.itemId }],
            })),
        };
        const generator = SearchUtil.getTempSearchQueryGenerator(model, {}, 4, false);
        return generator();
    }

    dayChooserChange(days: DayChooserPref) {
        this.dows = AvailWeekly.getDowsFromPref(days.days.day);
        this.changeDetector.detectChanges();
    }

    onUtilizationChange() {
        this.changeDetector.detectChanges();
    }

    toggleCollapse(collapse: HTMLElement) {
        const collapsed = collapse.getAttribute("collapsed");
        this.renderer.setAttribute(collapse, "collapsed", String(!(collapsed === "true")));
    }

    hoursChange({ startHour, endHour }: { startHour: number; endHour: number }) {
        this.startHour = startHour;
        this.endHour = endHour;
        this.changeDetector.detectChanges();
    }

    onTabChange(tab: AvailWeekly.Tab) {
        this.tabChangeCallback?.(tab);
    }

    detectChanges() {
        // Grids refresh on input changes
        this.changeDetector.detectChanges();
    }

    refreshGrids() {
        for (const grid of this.weeklyGrids) grid.refresh();
    }

    showMore() {
        this.visibleSpaces += AvailWeekly.SPACES_STEP;
        this.changeDetector.detectChanges();
    }

    @Bind
    async setQuery(query: string) {
        this.query = query;
        this.queryGenerator = null;
        await this.refreshSearch();
    }

    @Bind
    setQueryGenerator(queryGenerator: () => Promise<{ query: string; postSearch?: () => void }>) {
        this.queryGenerator = queryGenerator;
        return this.refreshSearch();
    }
}
