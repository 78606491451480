import { Grid } from "../s25-virtual-grid/s25.virtual.grid.component";
import { S25Util } from "../../util/s25-util";
import { Proto } from "../../pojo/Proto";
import { S25Datefilter } from "../s25-dateformat/s25.datefilter.service";
import { AvailService } from "../../services/avail.service";
import { ValueOf } from "../../pojo/Util";
import { Event } from "../../pojo/Event";
import { tooltipRowType, TooltipService } from "../../services/tooltip.service";
import { Item } from "../../pojo/Item";
import { UserprefService } from "../../services/userpref.service";
import { ColorBucketType, ItemColorMappingService } from "../../services/item.color.mapping.service";
import { AvailUtil } from "../s25-availability/avail.util";
import { DaysOfWeekPrefDay } from "../../services/preference.service";
import { VisualizationType } from "../s25-opt/s25.opt.component";

export namespace AvailWeekly {
    import ISODateString = Proto.ISODateString;
    export const MAX_NUM_WEEKS = 20;
    export const MIN_SPACES = 5;
    export const SPACES_STEP = 5;
    export const WEEK_OPTIONS = Array.from(S25Util.range(1, MAX_NUM_WEEKS + 1));

    export type ColumnHeaderData = {
        type: "dow" | "date";
        isToday?: boolean; // Indicates whether the header is the same DOW as today
        date?: ISODateString;
    };

    export type RowHeaderData = {
        hour: number;
    };

    export type Mode = "overlapping" | "separated";
    export type UtilizationMode = "none" | "RHC/EHC" | "RHC/CAP" | "EHC/CAP";

    export type ItemData = {
        id: number;
        reservationId?: number;
        name: string;
        type: RsrvTypeName;
        dowId: AvailWeekly.DowId;
        prePercent: number;
        eventPercent: number;
        postPercent: number;
        eventState?: Event.State.Id;
        eventType?: number;
        isEvent: boolean;
        expectedHeadcount: number;
        registeredHeadcount: number;
        utilization: {
            "RHC/EHC": number;
            "RHC/CAP": number;
            "EHC/CAP": number;
        };
        dates: {
            setup: ISODateString;
            pre: ISODateString;
            start: ISODateString;
            end: ISODateString;
            post: ISODateString;
            takedown: ISODateString;
        };
    };

    export type Tab = VisualizationType;

    export type Dow = keyof typeof dows;
    export type DowId = ValueOf<typeof dows>;
    export const dows = {
        Sunday: 0,
        Monday: 1,
        Tuesday: 2,
        Wednesday: 3,
        Thursday: 4,
        Friday: 5,
        Saturday: 6,
    } as const;

    export type RsrvTypeId = ValueOf<typeof rsrvType>;
    export type RsrvTypeName = keyof typeof rsrvType;
    export const rsrvType = {
        Event: 1,
        Blackout: 2,
        Closed: 3,
        Pending: 4,
        Related: 5,
        Requested: 7,
        Draft: 8,
    } as const;
    export const rsrvTypeName = S25Util.reverseObject(rsrvType);
    export const eventRsrvTypes = new Set([rsrvType.Event, rsrvType.Related, rsrvType.Requested, rsrvType.Draft]);

    export const rsrvTypeLabel = {
        [rsrvType.Event]: "Default Event Green",
        [rsrvType.Blackout]: "Blackout Grey",
        [rsrvType.Closed]: "Closed Hours Grey",
        [rsrvType.Pending]: "Pending Event Purple",
        [rsrvType.Related]: "Related Event Orange",
        [rsrvType.Requested]: "Requested Event",
        [rsrvType.Draft]: "Draft Event",
    } as const;

    export function getDowArr(data: { dows: Set<Dow>; weekStart: DowId }) {
        const weekStart = data.weekStart ?? dows.Sunday;
        const dowArr: Dow[] = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
        S25Util.array.rotate(dowArr, -weekStart);
        return dowArr.filter((dow) => data.dows.has(dow));
    }

    export function getColumns(data: {
        weekStart: DowId;
        firstDate: Date;
        weeks: number;
        dows: Dow[];
    }): Grid.Header<ColumnHeaderData>[] {
        const { firstDate } = data;
        const weeks = S25Util.clamp(data.weeks ?? 1, 1, MAX_NUM_WEEKS);
        const weekStartDate = S25Util.date.firstDayOfWeekFromDate(firstDate, data.weekStart ?? dows.Sunday);
        const headers = data.dows.map((dow) => {
            const dowOffset = (dows[dow] - data.weekStart + 7) % 7; // Days from start of week
            const date = S25Util.date.addDays(weekStartDate, dowOffset);
            return dowToColumn({ dow, firstDate: date, weeks });
        });
        return headers;
    }

    export function dowToColumn(data: { dow: Dow; firstDate: Date; weeks?: number }): Grid.Header<ColumnHeaderData> {
        const { dow, firstDate } = data;
        const weeks = S25Util.clamp(data.weeks ?? 1, 1, MAX_NUM_WEEKS);
        const dates: Date[] = [];
        for (const week of S25Util.range(weeks)) {
            const date = S25Util.date.addDays(firstDate, 7 * week);
            dates.push(date);
        }
        return {
            id: dow,
            heading: dow,
            data: {
                type: "dow",
                isToday: dow === S25Util.date.getDayName(new Date().getDay()),
            },
            subHeaders: dates.map((date) => dateToColumn(date)),
        };
    }

    export function dateToColumn(date: Date, dateFormat: Proto.DateFormat = "M/d"): Grid.Header<ColumnHeaderData> {
        const dateStr = S25Util.date.toS25ISODateStr(date);
        return {
            id: dateStr,
            heading: S25Datefilter.transform(date, dateFormat),
            data: {
                type: "date",
                isToday: S25Util.date.isToday(date),
                date: dateStr,
            },
        };
    }

    export function getRows(data: {
        startHour?: number;
        endHour?: number;
        is24?: boolean;
    }): Grid.Header<RowHeaderData>[] {
        const is24 = !!data.is24;
        const startHour = S25Util.clamp(data.startHour ?? 0, 0, 23);
        const endHour = S25Util.clamp(data.endHour ?? 23, startHour, 23);

        const rows: Grid.Header<RowHeaderData>[] = [];
        for (const hour of S25Util.range(24)) {
            const hidden = hour < startHour || hour > endHour;
            rows.push(hourToRow({ hour, is24, hidden }));
        }

        return rows;
    }

    export function hourToRow(data: { hour: number; is24?: boolean; hidden?: boolean }): Grid.Header<RowHeaderData> {
        const { hour } = data;
        const is24 = !!data.is24;
        const hidden = data.hidden ?? true;
        return {
            id: hour,
            heading: S25Util.date.toTimeStrFromHours(hour, is24),
            data: { hour },
            hidden,
        };
    }

    export function getItems(data: {
        dows: Dow[];
        weeks: number;
        firstDate: Date;
        items: Awaited<ReturnType<typeof AvailService.getWeeklyAvailability>>;
        maxCapacity: number;
        colorMap: ColorBucketType;
        is24: boolean;
    }): Grid.Item<ItemData>[] {
        const dowIndex = new Map(data.dows.map((dow, i) => [dows[dow], i]));
        const columnCount = data.dows.length * data.weeks;
        return data.items
            .filter((item) => {
                if (!dowIndex.has(item.dow as DowId)) return false;
                const week = Math.floor(S25Util.date.diffDays(data.firstDate, item.date) / 7);
                if (week < 0 || week >= data.weeks) return false;
                return true;
            })
            .map((item) => {
                const week = Math.floor(S25Util.date.diffDays(data.firstDate, item.date) / 7);
                const eventState = item.cur_event_state === "" ? undefined : item.cur_event_state;
                const eventType = item.event_type_id === "" ? undefined : item.event_type_id;
                const rsrvType = item.rsrvType as RsrvTypeId;
                const color = ItemColorMappingService.getColor(data.colorMap, eventState, eventType);
                const colorName = color?.bucket_name || AvailWeekly.rsrvTypeLabel[rsrvType];
                const ariaDate = item.date.toString().slice(0, 15);
                const ariaStart = AvailUtil.getAriaTime(item.start, data.is24);
                const ariaEnd = AvailUtil.getAriaTime(item.end, data.is24);
                return {
                    id: `${item.dow},${item.itemId},${item.start}`,
                    left: (100 / columnCount) * (dowIndex.get(item.dow as DowId) * data.weeks + week),
                    width: 100 / columnCount,
                    top: (100 / 24) * item.start,
                    height: (100 / 24) * (item.end - item.start),
                    data: AvailWeekly.getItemData(item, data.maxCapacity),
                    ariaLabel: `${item.itemName}, ${ariaDate} from ${ariaStart} until ${ariaEnd}, ${colorName}`,
                };
            });
    }

    export function getItemData(
        item: Awaited<ReturnType<typeof AvailService.getWeeklyAvailability>>[number],
        maxCapacity: number,
    ): ItemData {
        const eventState = item.cur_event_state === "" ? undefined : item.cur_event_state;
        const eventType = item.event_type_id === "" ? undefined : item.event_type_id;
        const expected = item.expectedHeadcount || 0;
        const registered = item.registeredHeadcount || 0;
        const rsrvType = item.rsrvType as RsrvTypeId;

        return {
            id: item.itemId,
            reservationId: item.itemId2 || null,
            name: item.itemName,
            type: rsrvTypeName[rsrvType] as RsrvTypeName,
            dowId: item.dow as AvailWeekly.DowId,
            prePercent: item.prePerc,
            eventPercent: item.evPerc,
            postPercent: item.postPerc,
            eventState,
            eventType,
            isEvent: eventRsrvTypes.has(item.rsrvType),
            expectedHeadcount: expected,
            registeredHeadcount: registered,
            utilization: {
                "RHC/EHC": registered / expected,
                "RHC/CAP": registered / maxCapacity,
                "EHC/CAP": expected / maxCapacity,
            },
            dates: {
                setup: S25Util.date.dropTZFromISODateTimeString(item.setup_dt || ""),
                pre: S25Util.date.dropTZFromISODateTimeString(item.pre_dt || ""),
                start: S25Util.date.dropTZFromISODateTimeString(item.start_dt || ""),
                end: S25Util.date.dropTZFromISODateTimeString(item.end_dt || ""),
                post: S25Util.date.dropTZFromISODateTimeString(item.post_dt || ""),
                takedown: S25Util.date.dropTZFromISODateTimeString(item.takedown_dt || ""),
            },
        };
    }

    export function headersToEvent(headers: {
        columns: Grid._Header<ColumnHeaderData>[];
        rows: Grid._Header<RowHeaderData>[];
    }) {
        const startHour = headers.rows[0].data.hour;
        const endHour = headers.rows.at(-1).data.hour + 1;

        const startTime = S25Util.date.toS25ISOTimeStrFromHours(startHour);
        const endTime = S25Util.date.toS25ISOTimeStrFromHours(endHour);

        const event: Parameters<typeof AvailService.createEvent>[0] = {
            startTime,
            endTime,
            startDt: new Date(`${headers.columns[0].data.date}T${startTime}`),
            endDt: new Date(`${headers.columns.at(-1).data.date}T${endTime}`),
        };

        if (headers.columns.length === 1) return event;

        event.occurrences = headers.columns
            .sort((a, b) => (a.data.date < b.data.date ? -1 : 0))
            .map((header) => {
                const start = new Date(`${header.data.date}T${startTime}`);
                const end = new Date(`${header.data.date}T${endTime}`);

                if (start < event.startDt) event.startDt = start;
                if (end > event.endDt) event.endDt = end;

                return { startDt: start, endDt: end };
            });

        return event;
    }

    export function separateOverlappingItems(items: Grid.Item<AvailWeekly.ItemData>[]) {
        let maxTracks = 1;
        const itemsByColumn = S25Util.array.groupBy(items, (item) => item.left);
        for (const items of Object.values(itemsByColumn)) {
            if (!items?.length || items.length === 1) continue;

            items.sort((a, b) => a.top - b.top || a.height - b.height);
            const backgroundTypes: AvailWeekly.RsrvTypeName[] = ["Blackout", "Closed"];
            const foreground = items.filter((item) => !backgroundTypes.includes(item.data.type));
            if (!foreground.length) continue;

            const tracks = S25Util.array.getTracks(foreground, "top", "bottom", ({ top, height }) => ({
                top,
                bottom: top + height,
            }));
            if (tracks.length > maxTracks) maxTracks = tracks.length;

            const trackWidth = foreground[0].width / tracks.length;
            for (const [t, track] of S25Util.array.enumerate(tracks)) {
                const itemsToRight = S25Util.array.flatten(tracks.slice(t + 1));
                for (const item of track) {
                    const hasOverlap = itemsToRight.some(
                        (right) => item.top < right.top + right.height && item.top + item.height > right.top,
                    );

                    item.width = hasOverlap ? trackWidth : trackWidth * (tracks.length - t);
                    item.left += trackWidth * t;
                }
            }
        }

        return maxTracks;
    }

    export async function eventTooltipService(
        itemType: Item.Id,
        eventId: number,
        reservationId: number,
        itemData: ItemData,
    ) {
        const [data, timeFormat] = await Promise.all([
            TooltipService.getTooltip(itemType, eventId, reservationId),
            UserprefService.getS25Timeformat(),
        ]);

        const timeIndex = data.item.rows.data.findIndex((item) => item.name === "Event Time:");
        if (timeIndex !== -1) {
            const dates = itemData.dates;
            let additionalTime = "";
            if (dates.setup !== dates.pre)
                additionalTime += `<li><label>Setup:</label> <span>${S25Datefilter.transform(dates.setup, timeFormat)}</span></li>`;
            if (dates.pre !== dates.start)
                additionalTime += `<li><label>Pre:</label> <span>${S25Datefilter.transform(dates.pre, timeFormat)}</span></li>`;
            if (dates.post !== dates.end)
                additionalTime += `<li><label>Post:</label> <span>${S25Datefilter.transform(dates.post, timeFormat)}</span></li>`;
            if (dates.takedown !== dates.post)
                additionalTime += `<li><label>Takedown:</label> <span>${S25Datefilter.transform(dates.takedown, timeFormat)}</span></li>`;
            if (additionalTime) {
                data.item.rows.data.splice(timeIndex + 1, 0, {
                    name: "Additional Time:",
                    type: tooltipRowType.HTML,
                    value: `<ul class="availWeeklyGlobal">${additionalTime}</ul>`,
                });
            }
        }

        data.item.rows.data.push({
            name: "Head Count:",
            type: tooltipRowType.HTML,
            value: `
<ul class="availWeeklyGlobal">
    <li><label>Expected:</label> <span>${itemData.expectedHeadcount}</span></li>
    <li><label>Registered:</label> <span>${itemData.registeredHeadcount}</span></li>
</ul>
                `,
        });

        return data;
    }

    export function getDowsFromPref(days: DaysOfWeekPrefDay[]) {
        return new Set(days.filter((day) => day.show === "true").map((day) => day.name));
    }
}
