import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    HostBinding,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
    ViewEncapsulation,
} from "@angular/core";
import { S25VirtualGridModule } from "../s25-virtual-grid/s25.virtual.grid.module";
import { Grid, S25VirtualGridComponent } from "../s25-virtual-grid/s25.virtual.grid.component";
import { Bind } from "../../decorators/bind.decorator";
import { TypeManagerDecorator } from "../../main/type.map.service";
import { AvailService, EventPerm } from "../../services/avail.service";
import { Item } from "../../pojo/Item";
import { S25Util } from "../../util/s25-util";
import { AvailWeekly } from "./s25.avail.weekly.util";
import { UserprefService } from "../../services/userpref.service";
import { S25ItemModule } from "../s25-item/s25.item.module";
import { S25IconModule } from "../s25-icon/s25.icon.module";
import ColumnHeaderData = AvailWeekly.ColumnHeaderData;
import RowHeaderData = AvailWeekly.RowHeaderData;
import ItemData = AvailWeekly.ItemData;
import { S25OfficeHoursSliderModule } from "../s25-office-hours-slider/s25.office.hours.slider.module";
import { DaysOfWeekPref, DaysOfWeekPrefDay, PreferenceService } from "../../services/preference.service";
import { DecimalPipe, LowerCasePipe } from "@angular/common";
import { Memo } from "../../decorators/memo.decorator";
import { S25WsSpace, SpaceService } from "../../services/space.service";
import { S25DatetimePrefssUtil } from "../s25-datetime-prefs/s25.datetime.prefs.util";
import { S25AvailWeeklyOptionsComponent } from "./s25.avail.weekly.options.component";
import { ColorBucketType, ItemColorMappingService } from "../../services/item.color.mapping.service";
import { S25AvailLegendComponent } from "./s25.avail.legend.component";
import { S25ModalModule } from "../s25-modal/s25.modal.module";

@TypeManagerDecorator("s25-ng-avail-weekly")
@Component({
    selector: "s25-ng-avail-weekly",
    template: `
        <s25-ng-virtual-grid
            #virtualGrid
            [dataSource]="virtualGridDataSource"
            [columnHeaderTemplate]="columnHeader"
            [rowHeaderTemplate]="rowHeader"
            [createTemplate]="createTemplate"
            [cornerTemplate]="corner"
            [optionsBelowTemplate]="options"
            [itemTemplate]="item"
            [canCreate]="true"
            [canCreateDragX]="true"
            [canCreateDragY]="true"
            [showOptionsWhileLoading]="true"
            [style.--max-tracks]="maxTracks"
        />

        <ng-template #corner>
            <s25-ng-office-hours-slider
                [(start)]="startHour"
                [(end)]="endHour"
                (onChange)="hoursChange.emit({ startHour, endHour }); virtualGrid.refresh()"
            />
        </ng-template>

        <ng-template #options let-defaultOptions="defaultOptions">
            @if (!noOptions) {
                <s25-ng-avail-weekly-options
                    [(includeRequested)]="includeRequested"
                    [(firstDate)]="firstDate"
                    [(weeks)]="weeks"
                    (daysChange)="dayChooserChange($event)"
                    [(utilizationMode)]="utilizationMode"
                    (utilizationModeChange)="onUtilizationChange()"
                    [(mode)]="mode"
                    (legend)="legend.open()"
                    (changed)="refresh()"
                    (refresh)="refresh()"
                    [hasHelp]="hasHelp"
                />
            }
        </ng-template>

        <s25-ng-modal #legend [title]="'Availability Color Legend'" [size]="'xs'">
            <ng-template #s25ModalBody><s25-ng-avail-legend /></ng-template>
        </s25-ng-modal>

        <ng-template #columnHeader let-header="header">
            <div class="column-header {{ header.data.type }}" [class.today]="header.data.isToday">
                {{ header.heading }}
            </div>
        </ng-template>

        <ng-template #rowHeader let-header="header">
            <div class="row-header" [class.afternoon]="header.data.hour >= NOON">
                {{ header.heading }}
            </div>
        </ng-template>

        <ng-template #item let-item="item">
            <div
                class="item grid-color"
                [attr.data-type]="item.data.type | lowercase"
                [attr.data-event-type]="item.data.eventType"
                [attr.data-state]="item.data.eventState"
                [style.--pre-percent.%]="item.data.prePercent"
                [style.--event-percent.%]="item.data.eventPercent"
                [style.--post-percent.%]="item.data.postPercent"
                [attr.data-utilization]="hasUtilization(item, utilizationMode)"
                [style.--utilization.%]="item.data.utilization[utilizationMode] * 100"
            >
                @if (item.data.isEvent) {
                    <s25-item-event
                        [modelBean]="
                            $any({
                                itemId: item.data.id,
                                itemName: item.data.name,
                                itemId2: item.data.reservationId,
                            })
                        "
                        [empty]="true"
                        [contextMenuOnBody]="true"
                        [dataService]="eventTooltipService"
                    >
                        <div class="eventWrapper">
                            @if (!!item.data.prePercent) {
                                <div class="pre"></div>
                            }
                            <div class="event">
                                <span class="name">
                                    @if (hasUtilization(item, utilizationMode)) {
                                        {{ item.data.utilization[utilizationMode] * 100 | number: "1.0-0" }}%
                                    }
                                    {{ item.data.name }}
                                </span>
                            </div>
                            @if (!!item.data.postPercent) {
                                <div class="post"></div>
                            }
                        </div>
                    </s25-item-event>
                } @else {
                    <span class="name">{{ item.data.name }}</span>
                }
            </div>
        </ng-template>

        <ng-template #createTemplate>
            <button class="create-item aw-button aw-button--none"><s25-ng-icon [type]="'pen'" /></button>
        </ng-template>
    `,
    styles: `
        :host {
            --column-header-bg: #fff0eb;
            --column-header-today-bg: #f1c7b9;

            --row-header-blue-even: #88a7dd;
            --row-header-blue-odd: #94b5ef;
            --row-header-border-color-afternoon: #888;

            --event-border-color: black;
            --create-background: beige;
            --event-no-utilization-background: #d4d4d4;
            --event-no-utilization-color: black;

            --event-border: 1px solid var(--event-border-color);
            --item-overlap: 1px;

            /* Variables for the virtual grid */
            --grid-column-line-color: black;
            --grid-outline-border: 1px solid black;
            --grid-column-width-first: clamp(75px, 10cqi, 150px);
            --grid-column-width-min: 40px;
            --grid-column-width-max: 250px;
        }

        ::ng-deep .nm-party--on s25-ng-avail-weekly {
            --column-header-bg: #3c3d46;
            --column-header-today-bg: #2d2d34;

            --row-header-blue-even: #277abe;
            --row-header-blue-odd: #23659c;
            --row-header-border-color-afternoon: #888;

            --event-border-color: black;
            --create-background: beige;
            --event-no-utilization-background: #d4d4d4;
            --event-no-utilization-color: black;

            /* Variables for the virtual grid */
            --grid-column-line-color: var(--border-color);
            --grid-outline-border: 1px solid var(--border-color);
        }

        s25-ng-virtual-grid {
            --grid-column-step: calc(1px * var(--max-tracks));
        }

        .column-header {
            background-color: var(--column-header-bg);
        }

        .column-header.dow {
            font-size: min(1em, 8cqi + 0.5em);
            font-weight: bold;
            overflow: hidden;
        }

        .column-header.today {
            --column-header-bg: var(--column-header-today-bg);
        }

        .row-header {
            padding-inline: 0.5em;
            text-align: center;
            text-transform: uppercase;
        }

        .row-header.afternoon {
            background-color: var(--row-header-blue-odd);
        }

        .item {
            height: 100%;
            border: var(--event-border);
            text-align: center;
            overflow: hidden;
            font-size: min(1em, 8cqi + 0.5em);
            line-height: calc(var(--row-height) - 1px);
            user-select: text;
            position: relative;
        }

        .item:not([data-type="blackout"]):not([data-type="closed"]) {
            z-index: 1;
        }

        .item:where([data-type="pending"], [data-type="requested"], [data-type="draft"]) .name {
            background-color: var(--grid-color--background-color);
            padding: 0.1em;
            border-radius: 3px;
        }

        :host:not([data-utilization-mode="none"]) .item[data-utilization="false"]:not([data-type="blackout"]) {
            --grid-color--background-color: var(--event-no-utilization-background);
            --grid-color--color: var(--event-no-utilization-color);
        }

        .eventWrapper {
            height: 100%;
            display: flex;
            flex-direction: column;
        }

        .pre,
        .post {
            background-color: rgb(255 255 255 / 40%);
        }

        .pre {
            flex-basis: var(--pre-percent);
            flex-shrink: 1;
            border-bottom: 1px dotted black;
        }

        .post {
            flex-basis: var(--post-percent);
            flex-shrink: 1;
            border-top: 1px dotted black;
        }

        .event {
            text-overflow: ellipsis;
            overflow: hidden;
            flex-grow: 1;
            overflow-wrap: anywhere;
            text-wrap: wrap;
            padding-inline: 0.2em;
        }

        .create-item {
            height: 100%;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            text-align: center;
            overflow: hidden;
            font-size: min(1em, 8cqi + 0.5em);
            line-height: calc(var(--row-height) - 1px);
            user-select: text;

            s25-ng-icon {
                width: 11px; /* Center on the paper of the pen icon */
            }
        }

        :host ::ng-deep.grid--column-headers--sub-headers .leaf:not(:first-child) {
            --column-line-width: 1px;
            --column-line-color: var(--border-color);
        }

        :host ::ng-deep.grid--column-header.leaf {
            border-top-color: var(--column-header-bg) !important;
        }

        :host ::ng-deep .grid--column-header.leaf:has(.today) {
            border-top-color: var(--column-header-today-bg) !important;
        }

        :host ::ng-deep .grid--row-header.leaf:has(.row-header.afternoon):not(:last-child) {
            border-bottom-color: var(--row-header-border-color-afternoon);
        }

        :host ::ng-deep .grid--row-header.leaf:nth-child(odd) .row-header.afternoon {
            background-color: var(--row-header-blue-even);
        }

        :host ::ng-deep .grid--item {
            /* Make items on the right (left+width=100%) wider to prevent double border */
            --width-overlap: max(
                var(--item-left) + var(--item-width) - 100% + var(--item-overlap) * 2,
                var(--item-overlap)
            );
            left: calc(var(--item-left) - var(--item-overlap)) !important;
            width: calc(var(--item-width) + var(--width-overlap)) !important;
            top: calc(var(--item-top) - var(--item-overlap)) !important;
            height: calc(var(--item-height) + var(--item-overlap)) !important;
        }

        :host ::ng-deep .grid--create-item {
            transform: translate3d(
                calc(var(--item-left) - var(--item-overlap)),
                calc(var(--item-top) - var(--item-overlap)),
                0
            ) !important;
            width: calc(var(--item-width) + var(--item-overlap)) !important;
            height: calc(var(--item-height) + var(--item-overlap)) !important;
        }

        :host ::ng-deep .grid--area.creating .create-item {
            border: var(--event-border);
            background-color: var(--create-background);
        }

        :host ::ng-deep s25-item {
            cursor: pointer;
        }

        :host ::ng-deep s25-item-event,
        :host ::ng-deep s25-item-event .ngInlineBlock,
        :host ::ng-deep s25-item-event .ngInlineBlock > div,
        :host ::ng-deep s25-item-event .s25-item-holder {
            display: block;
            height: 100%;
            line-height: inherit;
        }

        ::ng-deep ul.availWeeklyGlobal {
            list-style-type: none;
            padding-left: 0;
            font-size: 0.9em;
            margin: 0;
        }

        ::ng-deep ul.availWeeklyGlobal label {
            font-style: italic;
            min-width: 6em;
        }

        ::ng-deep ul.availWeeklyGlobal span {
            padding: 0 !important;
        }
    `,
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.Emulated,
    imports: [
        S25VirtualGridModule,
        S25OfficeHoursSliderModule,
        S25AvailWeeklyOptionsComponent,
        LowerCasePipe,
        S25ItemModule,
        S25IconModule,
        DecimalPipe,
        S25AvailLegendComponent,
        S25ModalModule,
    ],
})
export class S25AvailWeeklyComponent implements OnInit, OnChanges {
    @Input({ required: true }) itemType: Item.Id;
    @Input({ required: true }) itemId: number;
    @Input() firstDate: Date = new Date();
    @Input() noOptions: boolean = false;
    @Input() includeRequested: boolean = false;
    @Input() weeks: number = 1;
    @Input() dows: Set<AvailWeekly.Dow>;
    @Input() mode: AvailWeekly.Mode = "overlapping";
    @Input() startHour: number = 0;
    @Input() endHour: number = 23;
    @Input() hasHelp: boolean = true;

    @Output() firstDateChange = new EventEmitter<Date>();
    @Output() hoursChange = new EventEmitter<{ startHour: number; endHour: number }>();

    @ViewChild("virtualGrid") virtualGrid: S25VirtualGridComponent<ColumnHeaderData, RowHeaderData, ItemData>;

    @HostBinding("attr.data-utilization-mode")
    @Input()
    utilizationMode: AvailWeekly.UtilizationMode = "none";

    isInit: boolean = false;
    weekStart: AvailWeekly.DowId = AvailWeekly.dows.Sunday;
    virtualGridDataSource: Grid.DataSource<ColumnHeaderData, RowHeaderData, ItemData>;
    data: Grid.Data<ColumnHeaderData, RowHeaderData, ItemData>;
    dowArr: AvailWeekly.Dow[];
    perms: EventPerm;
    instanceTZ: string;
    userTZ: string;
    is24: boolean;
    location: S25WsSpace;
    itemByReservationId: Map<number, Grid.Item<AvailWeekly.ItemData>>;
    colorMap: ColorBucketType;
    eventDisplay: "name" | "title" = "name";
    maxTracks: number = 1;

    protected readonly LAST_HOUR_OF_DAY = 23;
    protected readonly NOON = 12;

    constructor(private changeDetector: ChangeDetectorRef) {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes.startHour) this.startHour = S25Util.clamp(this.startHour ?? 0, 0, this.LAST_HOUR_OF_DAY);
        if (changes.endHour) this.endHour = S25Util.clamp(this.endHour ?? 0, 0, this.LAST_HOUR_OF_DAY);
        if (changes.dows) this.setDows(this.dows);

        const shouldRefresh =
            changes.itemType ||
            changes.itemId ||
            changes.firstDate ||
            changes.includeRequested ||
            changes.firstDate ||
            changes.weeks ||
            changes.dows ||
            changes.mode ||
            changes.startHour ||
            changes.endHour;
        if (this.isInit && shouldRefresh) {
            this.refresh();
        }
    }

    async ngOnInit() {
        ItemColorMappingService.loadCss();
        this.virtualGridDataSource = {
            getData: this.getData,
            onCreateShow: this.canCreateAt,
            onCreateDrag: this.canCreateAt,
            onCreate: this.onCreate,
        };
    }

    @Bind
    async getData(query: Grid.DataQuery): Promise<Grid.Data<ColumnHeaderData, RowHeaderData, ItemData>> {
        this.itemId = Number(this.itemId);

        if (!this.isInit) {
            const [officeHours, eventDisplay, weekStart] = await Promise.all([
                PreferenceService.getOfficeHours(),
                PreferenceService.getEventDisplay(),
                UserprefService.getWeekstart(),
            ]);
            this.startHour = officeHours.start;
            this.endHour = officeHours.end;
            this.eventDisplay = eventDisplay;
            this.weekStart = weekStart;
        }

        const [data] = await Promise.all([
            AvailService.getWeeklyAvailability({
                displayEndTime: this.endHour,
                displayStartTime: this.startHour,
                isItemNameTitle: this.eventDisplay === "title",
                itemId: this.itemId,
                itemType: this.itemType,
                numWeeks: this.weeks,
                startDt: this.firstDate,
                includeRequested: this.includeRequested,
                weekStart: this.weekStart,
            }),
            this.getPreferences(),
        ]);

        const newFirstDate = S25Util.date.firstDayOfWeekFromDate(this.firstDate, this.weekStart);
        if (!S25Util.date.equalDate(this.firstDate, newFirstDate)) {
            this.firstDate = newFirstDate;
            this.firstDateChange.emit(this.firstDate);
        }

        const headers = AvailWeekly.getColumns({
            weekStart: this.weekStart,
            firstDate: this.firstDate,
            weeks: this.weeks,
            dows: this.dowArr,
        });
        const rows = AvailWeekly.getRows({ startHour: this.startHour, endHour: this.endHour, is24: this.is24 });
        const items = AvailWeekly.getItems({
            items: data,
            dows: this.dowArr,
            weeks: this.weeks,
            firstDate: this.firstDate,
            maxCapacity: this.location.max_capacity || 0,
            colorMap: this.colorMap,
            is24: this.is24,
        });
        this.itemByReservationId = new Map(items.map((item) => [item.data.reservationId, item]));

        this.maxTracks = 1;
        if (this.mode === "separated") {
            this.maxTracks = AvailWeekly.separateOverlappingItems(items);
        }

        this.data = { headers, rows, items };
        this.isInit = true;

        return this.data;
    }

    async getPreferences() {
        const [is24, days, perms, instanceTZ, userTZ, space, colorMap, separated] = await Promise.all([
            UserprefService.getIs24HourTime(),
            PreferenceService.getDays(),
            SpaceService.getSpaceAssignPermsPage(`&space_id=${this.itemId}`),
            PreferenceService.getPreferences(["timezone"], "S"),
            UserprefService.getTZName(),
            SpaceService.getSpaceMinimal(this.itemId),
            ItemColorMappingService.getEnabledBucketType(),
            AvailService.getSeparatedPref(),
        ]);
        this.is24 = is24;
        if (!this.isInit) this.setDowsFromPref(days);
        this.perms = perms?.items?.get(this.itemId);
        this.instanceTZ = S25DatetimePrefssUtil.timeZoneToIANA[instanceTZ?.timezone?.value || 25];
        this.userTZ = userTZ;
        this.location = space;
        this.colorMap = colorMap;
        this.mode = separated ? "separated" : "overlapping";
    }

    @Bind
    @Memo({
        hasher: (createData: Grid.CreateData) => [createData.left, createData.top, createData.height, createData.width],
        scopeToContext: true,
    })
    async canCreateAt(createData: Grid.CreateData) {
        if (!this.perms) return false;
        if (this.perms.assignOverride) return true;

        const headers = this.virtualGrid.positionToHeaders<ColumnHeaderData, RowHeaderData>(createData);
        const event = AvailWeekly.headersToEvent(headers);

        return (event.occurrences || [event]).every((occ: { startDt: Date; endDt: Date }) => {
            return this.hasCreatePermsAt(occ.startDt, occ.endDt);
        });
    }

    hasCreatePermsAt(start: Date, end: Date) {
        if (!this.perms) return false;
        const { assignOverride, assignPerm, exceptionDateList, exceptionDays, dateBuffer } = this.perms;
        return AvailService.hasEventPerms(
            "create",
            assignPerm,
            exceptionDateList,
            exceptionDays,
            dateBuffer,
            S25Util.date.timezone.convertTo(start, this.userTZ),
            S25Util.date.timezone.convertTo(end, this.userTZ),
            { instance: this.instanceTZ, user: this.userTZ },
        );
    }

    @Bind
    async onCreate(createData: Grid.CreateData) {
        const headers = this.virtualGrid.positionToHeaders<ColumnHeaderData, RowHeaderData>(createData);
        const event = AvailWeekly.headersToEvent(headers);
        event.itemId = this.itemId;

        await AvailService.createEvent(event, this.itemType);
    }

    setDowsFromPref(days: DaysOfWeekPrefDay[]) {
        const dows = AvailWeekly.getDowsFromPref(days);
        this.setDows(dows);
    }

    setDows(dows: Set<AvailWeekly.Dow>) {
        this.dows = dows;
        this.dowArr = AvailWeekly.getDowArr({ dows: this.dows, weekStart: this.weekStart });
    }

    dayChooserChange(days: DaysOfWeekPref) {
        this.setDowsFromPref(days.days.day);
        return this.refresh();
    }

    refresh() {
        return this.virtualGrid.refresh();
    }

    onUtilizationChange() {
        this.changeDetector.detectChanges();
    }

    hasUtilization(item: Grid.Item<AvailWeekly.ItemData>, utilizationMode: AvailWeekly.UtilizationMode) {
        if (utilizationMode === "none") return false;
        if (item.data.type === "Related") return false;
        return isFinite(item.data.utilization[utilizationMode]);
    }

    @Bind
    async eventTooltipService(itemType: Item.Id, eventId: number, reservationId: number) {
        const item = this.itemByReservationId.get(reservationId);
        return await AvailWeekly.eventTooltipService(itemType, eventId, reservationId, item.data);
    }
}
